var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-system-organ" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c(
          "div",
          [
            _c("el-button", {
              staticClass: "back-btn",
              attrs: {
                icon: "el-icon-arrow-left",
                size: "small",
                type: "primary",
                plain: "",
              },
              on: { click: _vm.$goBack },
            }),
            _c("label", { staticClass: "label" }, [_vm._v("组织管理")]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "js-common-content" }, [
        _c(
          "div",
          { staticClass: "tree-box" },
          [
            _c("el-tree", {
              staticClass: "tree",
              attrs: {
                data: _vm.treeData,
                "node-key": "id",
                props: _vm.props,
                "default-expand-all": true,
                "highlight-current": true,
                "check-on-click-node": true,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ node }) {
                    return _c("span", { staticClass: "custom-tree-node" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(node.label)),
                      ]),
                      _c("span", { staticClass: "tree-btn" }, [
                        node.level != 3
                          ? _c("i", {
                              staticClass: "el-icon-plus",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.append(node)
                                },
                              },
                            })
                          : _vm._e(),
                        node.level != 1
                          ? _c("i", {
                              staticClass: "el-icon-edit-outline",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.edit(node)
                                },
                              },
                            })
                          : _vm._e(),
                        node.level != 1
                          ? _c("i", {
                              staticClass: "el-icon-delete",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.remove(node)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ])
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("add-organization", {
        attrs: { currentItem: _vm.currentItem, isModify: _vm.isModify },
        on: { handleItem: _vm.handleItem },
        model: {
          value: _vm.addDialogVisible,
          callback: function ($$v) {
            _vm.addDialogVisible = $$v
          },
          expression: "addDialogVisible",
        },
      }),
      _c("common-delete", {
        on: { handleDelete: _vm.handleDelete },
        model: {
          value: _vm.deleteDialogVisible,
          callback: function ($$v) {
            _vm.deleteDialogVisible = $$v
          },
          expression: "deleteDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }