<template>
  <div class="js-common-wrap js-system-organ">
    <div class="js-common-head">
      <div>
        <el-button class="back-btn" @click="$goBack" icon="el-icon-arrow-left" size="small" type="primary" plain></el-button>
        <label class="label">组织管理</label>
      </div>
    </div>
    <div class="js-common-content">
      <div class="tree-box">
        <el-tree 
          class="tree" 
          :data="treeData" 
          node-key="id" 
          :props="props"
          :default-expand-all="true"
          :highlight-current="true" 
          :check-on-click-node="true">
          <span class="custom-tree-node" slot-scope="{ node }">
            <span class="label">{{ node.label }}</span>
            <span class="tree-btn">
              <i class="el-icon-plus" v-if="node.level != 3" @click.stop="append(node)"></i>
              <i class="el-icon-edit-outline" v-if="node.level != 1" @click.stop="edit(node)"></i>
              <i class="el-icon-delete" v-if="node.level != 1" @click.stop="remove(node)"></i>
            </span>
          </span>
        </el-tree>
      </div>
    </div>
    <add-organization v-model="addDialogVisible" :currentItem="currentItem" :isModify="isModify" @handleItem="handleItem"></add-organization>
    <common-delete v-model="deleteDialogVisible" @handleDelete="handleDelete"></common-delete>
  </div>
</template>
<script>
import AddOrganization from '@/components/AddOrganization'
import CommonDelete from '@/components/CommonDelete'
export default {
  name: '',
  components:{
    AddOrganization,
    CommonDelete
  },
  data() {
    return {
      props: {
        label: 'name',
        children: 'deptList',
      },
      treeData: [],
      addDialogVisible: false,
      deleteDialogVisible: false,
      isModify: '',
      parentId: '',
      currentItem: ''
    }
  },
  mounted() {
    this.getDept()
  },
  methods: {
    getDept(){
      this.$axios({
        method: 'get',
        url: 'api/csp/mgr/v1/dept/tree'
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.treeData = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    append(node) {
      this.isModify = false
      this.parentId = node.key
      this.currentItem = {
        id: '',
        label: ''
      }
      this.addDialogVisible = true
    },
    edit(node) {
      this.isModify = true
      this.currentItem = {
        id: node.key,
        label: node.label
      }
      this.addDialogVisible = true
    },
    handleItem(params){
      let method = ''
      let url = ''
      let options = ''
      if(this.isModify){
        method = 'put'
        url = `api/csp/mgr/v1/dept/${this.currentItem.id}`
        options = {
          name: params.label
        }
      }else{
        method = 'post'
        url = 'api/csp/mgr/v1/dept/'
        options = {
          name: params.label,
          parentId: this.parentId
        }
      }
      this.$axios({
        method: method,
        url: url,
        data: {
          data: options
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.addDialogVisible = false
          this.$message({
            type: 'success',
            message: '保存成功！',
            duration: 1000
          })
          this.getDept()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    remove(node){
      this.currentItem = node
      this.deleteDialogVisible = true
    },
    handleDelete(){
      this.$axios({
        method: 'delete',
        url: `api/csp/mgr/v1/dept/${this.currentItem.key}`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.deleteDialogVisible = false
          this.$message({
            type: 'success',
            message: '删除成功！',
            duration: 1000
          })
          this.getDept()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>


</style>
<style lang="scss">
</style>
