<template>
  <el-dialog class="js-dialog-form" :title="isModify?'修改组织名称':'添加组织'" :visible.sync="dialogVisible" :close-on-click-modal="false" width="560px">
    <ul>
      <!-- <li class="label common-tip"><i>*</i>组织名称</li> -->
      <li>
        <div class="label common-tip"><i>*</i>组织名称</div>
        <el-input v-model="params.label" placeholder="请输入组织名称" size="small"></el-input>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: ['value','currentItem','isModify'],
  data() {
    return {}
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    },
     params(){
      return this.currentItem
    }
  },
  mounted() {},
  methods: {
    handleCancel(){
      this.dialogVisible = false
    },
    handleConfirm(){
      this.$emit('handleItem',this.params)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>